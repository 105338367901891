import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from "react-query";
import { useFetchData } from "hooks/fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  ChainID: any;
  Cursor: any;
  Felt: any;
  JSON: any;
  Long: any;
  Time: any;
  Upload: any;
};

export type Account = Node & {
  __typename?: "Account";
  controllers?: Maybe<Array<Controller>>;
  createdAt: Scalars["Time"];
  credentials: Credentials;
  id: Scalars["ID"];
  membership: AccountTeamConnection;
  name?: Maybe<Scalars["String"]>;
  teams: TeamConnection;
  updatedAt: Scalars["Time"];
};

export type AccountMembershipArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AccountTeamWhereInput>;
};

export type AccountTeamsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TeamWhereInput>;
};

/** A connection to a list of items. */
export type AccountConnection = {
  __typename?: "AccountConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: "AccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

/** Ordering options for Account connections */
export type AccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Accounts. */
  field: AccountOrderField;
};

/** Properties by which Account connections can be ordered. */
export enum AccountOrderField {
  CreatedAt = "CREATED_AT",
}

export type AccountTeam = Node & {
  __typename?: "AccountTeam";
  account: Account;
  accountID: Scalars["ID"];
  id: Scalars["ID"];
  role: AccountTeamRole;
  team: Team;
  teamID: Scalars["ID"];
};

/** A connection to a list of items. */
export type AccountTeamConnection = {
  __typename?: "AccountTeamConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountTeamEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AccountTeamEdge = {
  __typename?: "AccountTeamEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<AccountTeam>;
};

/** AccountTeamRole is enum for the field role */
export enum AccountTeamRole {
  Owner = "owner",
}

/**
 * AccountTeamWhereInput is used for filtering AccountTeam objects.
 * Input was generated by ent.
 */
export type AccountTeamWhereInput = {
  and?: InputMaybe<Array<AccountTeamWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<AccountTeamWhereInput>;
  or?: InputMaybe<Array<AccountTeamWhereInput>>;
  /** role field predicates */
  role?: InputMaybe<AccountTeamRole>;
  roleIn?: InputMaybe<Array<AccountTeamRole>>;
  roleNEQ?: InputMaybe<AccountTeamRole>;
  roleNotIn?: InputMaybe<Array<AccountTeamRole>>;
};

/**
 * AccountWhereInput is used for filtering Account objects.
 * Input was generated by ent.
 */
export type AccountWhereInput = {
  and?: InputMaybe<Array<AccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars["Time"]>;
  createdAtGT?: InputMaybe<Scalars["Time"]>;
  createdAtGTE?: InputMaybe<Scalars["Time"]>;
  createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  createdAtLT?: InputMaybe<Scalars["Time"]>;
  createdAtLTE?: InputMaybe<Scalars["Time"]>;
  createdAtNEQ?: InputMaybe<Scalars["Time"]>;
  createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  /** controllers edge predicates */
  hasControllers?: InputMaybe<Scalars["Boolean"]>;
  hasControllersWith?: InputMaybe<Array<ControllerWhereInput>>;
  /** membership edge predicates */
  hasMembership?: InputMaybe<Scalars["Boolean"]>;
  hasMembershipWith?: InputMaybe<Array<AccountTeamWhereInput>>;
  /** teams edge predicates */
  hasTeams?: InputMaybe<Scalars["Boolean"]>;
  hasTeamsWith?: InputMaybe<Array<TeamWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** name field predicates */
  name?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  nameContainsFold?: InputMaybe<Scalars["String"]>;
  nameEqualFold?: InputMaybe<Scalars["String"]>;
  nameGT?: InputMaybe<Scalars["String"]>;
  nameGTE?: InputMaybe<Scalars["String"]>;
  nameHasPrefix?: InputMaybe<Scalars["String"]>;
  nameHasSuffix?: InputMaybe<Scalars["String"]>;
  nameIn?: InputMaybe<Array<Scalars["String"]>>;
  nameIsNil?: InputMaybe<Scalars["Boolean"]>;
  nameLT?: InputMaybe<Scalars["String"]>;
  nameLTE?: InputMaybe<Scalars["String"]>;
  nameNEQ?: InputMaybe<Scalars["String"]>;
  nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
  nameNotNil?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<AccountWhereInput>;
  or?: InputMaybe<Array<AccountWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars["Time"]>;
  updatedAtGT?: InputMaybe<Scalars["Time"]>;
  updatedAtGTE?: InputMaybe<Scalars["Time"]>;
  updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  updatedAtLT?: InputMaybe<Scalars["Time"]>;
  updatedAtLTE?: InputMaybe<Scalars["Time"]>;
  updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
  updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type Controller = Node & {
  __typename?: "Controller";
  account: Account;
  accountID: Scalars["ID"];
  address: Scalars["String"];
  createdAt: Scalars["Time"];
  id: Scalars["ID"];
  network: Scalars["String"];
  signers?: Maybe<Array<Signer>>;
  updatedAt: Scalars["Time"];
};

/** Ordering options for Controller connections */
export type ControllerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Controllers. */
  field: ControllerOrderField;
};

/** Properties by which Controller connections can be ordered. */
export enum ControllerOrderField {
  CreatedAt = "CREATED_AT",
}

/**
 * ControllerWhereInput is used for filtering Controller objects.
 * Input was generated by ent.
 */
export type ControllerWhereInput = {
  /** account_id field predicates */
  accountID?: InputMaybe<Scalars["ID"]>;
  accountIDContains?: InputMaybe<Scalars["ID"]>;
  accountIDContainsFold?: InputMaybe<Scalars["ID"]>;
  accountIDEqualFold?: InputMaybe<Scalars["ID"]>;
  accountIDGT?: InputMaybe<Scalars["ID"]>;
  accountIDGTE?: InputMaybe<Scalars["ID"]>;
  accountIDHasPrefix?: InputMaybe<Scalars["ID"]>;
  accountIDHasSuffix?: InputMaybe<Scalars["ID"]>;
  accountIDIn?: InputMaybe<Array<Scalars["ID"]>>;
  accountIDLT?: InputMaybe<Scalars["ID"]>;
  accountIDLTE?: InputMaybe<Scalars["ID"]>;
  accountIDNEQ?: InputMaybe<Scalars["ID"]>;
  accountIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** address field predicates */
  address?: InputMaybe<Scalars["String"]>;
  addressContains?: InputMaybe<Scalars["String"]>;
  addressContainsFold?: InputMaybe<Scalars["String"]>;
  addressEqualFold?: InputMaybe<Scalars["String"]>;
  addressGT?: InputMaybe<Scalars["String"]>;
  addressGTE?: InputMaybe<Scalars["String"]>;
  addressHasPrefix?: InputMaybe<Scalars["String"]>;
  addressHasSuffix?: InputMaybe<Scalars["String"]>;
  addressIn?: InputMaybe<Array<Scalars["String"]>>;
  addressLT?: InputMaybe<Scalars["String"]>;
  addressLTE?: InputMaybe<Scalars["String"]>;
  addressNEQ?: InputMaybe<Scalars["String"]>;
  addressNotIn?: InputMaybe<Array<Scalars["String"]>>;
  and?: InputMaybe<Array<ControllerWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars["Time"]>;
  createdAtGT?: InputMaybe<Scalars["Time"]>;
  createdAtGTE?: InputMaybe<Scalars["Time"]>;
  createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  createdAtLT?: InputMaybe<Scalars["Time"]>;
  createdAtLTE?: InputMaybe<Scalars["Time"]>;
  createdAtNEQ?: InputMaybe<Scalars["Time"]>;
  createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars["Boolean"]>;
  hasAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** signers edge predicates */
  hasSigners?: InputMaybe<Scalars["Boolean"]>;
  hasSignersWith?: InputMaybe<Array<SignerWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** network field predicates */
  network?: InputMaybe<Scalars["String"]>;
  networkContains?: InputMaybe<Scalars["String"]>;
  networkContainsFold?: InputMaybe<Scalars["String"]>;
  networkEqualFold?: InputMaybe<Scalars["String"]>;
  networkGT?: InputMaybe<Scalars["String"]>;
  networkGTE?: InputMaybe<Scalars["String"]>;
  networkHasPrefix?: InputMaybe<Scalars["String"]>;
  networkHasSuffix?: InputMaybe<Scalars["String"]>;
  networkIn?: InputMaybe<Array<Scalars["String"]>>;
  networkLT?: InputMaybe<Scalars["String"]>;
  networkLTE?: InputMaybe<Scalars["String"]>;
  networkNEQ?: InputMaybe<Scalars["String"]>;
  networkNotIn?: InputMaybe<Array<Scalars["String"]>>;
  not?: InputMaybe<ControllerWhereInput>;
  or?: InputMaybe<Array<ControllerWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars["Time"]>;
  updatedAtGT?: InputMaybe<Scalars["Time"]>;
  updatedAtGTE?: InputMaybe<Scalars["Time"]>;
  updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  updatedAtLT?: InputMaybe<Scalars["Time"]>;
  updatedAtLTE?: InputMaybe<Scalars["Time"]>;
  updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
  updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type CreateKatanaConfigInput = {
  accounts?: InputMaybe<Scalars["Int"]>;
  blockTime?: InputMaybe<Scalars["Int"]>;
  dev?: InputMaybe<Scalars["Boolean"]>;
  disableFee?: InputMaybe<Scalars["Boolean"]>;
  forkBlockNumber?: InputMaybe<Scalars["Long"]>;
  forkRpcUrl?: InputMaybe<Scalars["String"]>;
  gasPrice?: InputMaybe<Scalars["Long"]>;
  genesis?: InputMaybe<Scalars["String"]>;
  invokeMaxSteps?: InputMaybe<Scalars["Long"]>;
  seed?: InputMaybe<Scalars["String"]>;
  validateMaxSteps?: InputMaybe<Scalars["Long"]>;
};

export type CreateSayaConfigInput = {
  batchSize: Scalars["Int"];
  mode: Scalars["String"];
  privateKey: Scalars["String"];
  proverUrl: Scalars["String"];
  registry: Scalars["String"];
  rpcUrl: Scalars["String"];
  settlementContract: Scalars["String"];
  signerAddress: Scalars["String"];
  signerKey: Scalars["String"];
  starknetUrl: Scalars["String"];
  startBlock: Scalars["Int"];
  storeProofs: Scalars["Boolean"];
  world: Scalars["String"];
};

export type CreateServiceConfigInput = {
  katana?: InputMaybe<CreateKatanaConfigInput>;
  saya?: InputMaybe<CreateSayaConfigInput>;
  torii?: InputMaybe<CreateToriiConfigInput>;
};

export type CreateServiceInput = {
  config?: InputMaybe<CreateServiceConfigInput>;
  type: DeploymentService;
  version?: InputMaybe<Scalars["String"]>;
};

export type CreateToriiConfigInput = {
  indexPending?: InputMaybe<Scalars["Boolean"]>;
  pollingInterval?: InputMaybe<Scalars["Long"]>;
  rpc?: InputMaybe<Scalars["String"]>;
  startBlock?: InputMaybe<Scalars["Long"]>;
  world: Scalars["String"];
};

export type Credentials = {
  __typename?: "Credentials";
  webauthn?: Maybe<Array<WebauthnCredential>>;
};

export enum CurrencyBase {
  Usd = "USD",
}

export enum CurrencyQuote {
  Btc = "BTC",
  Eth = "ETH",
}

export type Deployment = Node & {
  __typename?: "Deployment";
  autoUpgrade: Scalars["Boolean"];
  branch?: Maybe<Scalars["String"]>;
  config: DeploymentConfig;
  createdAt: Scalars["Time"];
  events?: Maybe<Array<DeploymentLog>>;
  id: Scalars["ID"];
  logs: Logs;
  project: Scalars["String"];
  regions: Array<Scalars["String"]>;
  service: Service;
  serviceID: Scalars["ID"];
  spinDownAt?: Maybe<Scalars["Time"]>;
  spinUpAt?: Maybe<Scalars["Time"]>;
  status: DeploymentStatus;
  teams: TeamConnection;
  tier: DeploymentTier;
  updatedAt: Scalars["Time"];
};

export type DeploymentLogsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Order>;
  since?: InputMaybe<Scalars["Time"]>;
};

export type DeploymentTeamsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TeamWhereInput>;
};

export type DeploymentConfig = KatanaConfig | SayaConfig | ToriiConfig;

/** A connection to a list of items. */
export type DeploymentConnection = {
  __typename?: "DeploymentConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeploymentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DeploymentEdge = {
  __typename?: "DeploymentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<Deployment>;
};

export type DeploymentLog = Node & {
  __typename?: "DeploymentLog";
  deployment: Deployment;
  deploymentID: Scalars["ID"];
  id: Scalars["ID"];
  logType: DeploymentLogLogType;
  timestamp: Scalars["Time"];
};

/** A connection to a list of items. */
export type DeploymentLogConnection = {
  __typename?: "DeploymentLogConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeploymentLogEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DeploymentLogEdge = {
  __typename?: "DeploymentLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<DeploymentLog>;
};

/** DeploymentLogLogType is enum for the field log_type */
export enum DeploymentLogLogType {
  Created = "created",
  Deleted = "deleted",
  ScaledDown = "scaled_down",
  ScaledUp = "scaled_up",
}

/**
 * DeploymentLogWhereInput is used for filtering DeploymentLog objects.
 * Input was generated by ent.
 */
export type DeploymentLogWhereInput = {
  and?: InputMaybe<Array<DeploymentLogWhereInput>>;
  /** deployment_id field predicates */
  deploymentID?: InputMaybe<Scalars["ID"]>;
  deploymentIDContains?: InputMaybe<Scalars["ID"]>;
  deploymentIDContainsFold?: InputMaybe<Scalars["ID"]>;
  deploymentIDEqualFold?: InputMaybe<Scalars["ID"]>;
  deploymentIDGT?: InputMaybe<Scalars["ID"]>;
  deploymentIDGTE?: InputMaybe<Scalars["ID"]>;
  deploymentIDHasPrefix?: InputMaybe<Scalars["ID"]>;
  deploymentIDHasSuffix?: InputMaybe<Scalars["ID"]>;
  deploymentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
  deploymentIDLT?: InputMaybe<Scalars["ID"]>;
  deploymentIDLTE?: InputMaybe<Scalars["ID"]>;
  deploymentIDNEQ?: InputMaybe<Scalars["ID"]>;
  deploymentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** deployment edge predicates */
  hasDeployment?: InputMaybe<Scalars["Boolean"]>;
  hasDeploymentWith?: InputMaybe<Array<DeploymentWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** log_type field predicates */
  logType?: InputMaybe<DeploymentLogLogType>;
  logTypeIn?: InputMaybe<Array<DeploymentLogLogType>>;
  logTypeNEQ?: InputMaybe<DeploymentLogLogType>;
  logTypeNotIn?: InputMaybe<Array<DeploymentLogLogType>>;
  not?: InputMaybe<DeploymentLogWhereInput>;
  or?: InputMaybe<Array<DeploymentLogWhereInput>>;
  /** timestamp field predicates */
  timestamp?: InputMaybe<Scalars["Time"]>;
  timestampGT?: InputMaybe<Scalars["Time"]>;
  timestampGTE?: InputMaybe<Scalars["Time"]>;
  timestampIn?: InputMaybe<Array<Scalars["Time"]>>;
  timestampLT?: InputMaybe<Scalars["Time"]>;
  timestampLTE?: InputMaybe<Scalars["Time"]>;
  timestampNEQ?: InputMaybe<Scalars["Time"]>;
  timestampNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

/** Ordering options for Deployment connections */
export type DeploymentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Deployments. */
  field: DeploymentOrderField;
};

/** Properties by which Deployment connections can be ordered. */
export enum DeploymentOrderField {
  CreatedAt = "CREATED_AT",
}

export enum DeploymentService {
  Katana = "katana",
  Saya = "saya",
  Torii = "torii",
}

/** DeploymentStatus is enum for the field status */
export enum DeploymentStatus {
  Active = "active",
  Disabled = "disabled",
}

/** DeploymentTier is enum for the field tier */
export enum DeploymentTier {
  Basic = "basic",
  Common = "common",
  Epic = "epic",
  Legendary = "legendary",
  Rare = "rare",
  Uncommon = "uncommon",
}

/**
 * DeploymentWhereInput is used for filtering Deployment objects.
 * Input was generated by ent.
 */
export type DeploymentWhereInput = {
  and?: InputMaybe<Array<DeploymentWhereInput>>;
  /** auto_upgrade field predicates */
  autoUpgrade?: InputMaybe<Scalars["Boolean"]>;
  autoUpgradeNEQ?: InputMaybe<Scalars["Boolean"]>;
  /** branch field predicates */
  branch?: InputMaybe<Scalars["String"]>;
  branchContains?: InputMaybe<Scalars["String"]>;
  branchContainsFold?: InputMaybe<Scalars["String"]>;
  branchEqualFold?: InputMaybe<Scalars["String"]>;
  branchGT?: InputMaybe<Scalars["String"]>;
  branchGTE?: InputMaybe<Scalars["String"]>;
  branchHasPrefix?: InputMaybe<Scalars["String"]>;
  branchHasSuffix?: InputMaybe<Scalars["String"]>;
  branchIn?: InputMaybe<Array<Scalars["String"]>>;
  branchIsNil?: InputMaybe<Scalars["Boolean"]>;
  branchLT?: InputMaybe<Scalars["String"]>;
  branchLTE?: InputMaybe<Scalars["String"]>;
  branchNEQ?: InputMaybe<Scalars["String"]>;
  branchNotIn?: InputMaybe<Array<Scalars["String"]>>;
  branchNotNil?: InputMaybe<Scalars["Boolean"]>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars["Time"]>;
  createdAtGT?: InputMaybe<Scalars["Time"]>;
  createdAtGTE?: InputMaybe<Scalars["Time"]>;
  createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  createdAtLT?: InputMaybe<Scalars["Time"]>;
  createdAtLTE?: InputMaybe<Scalars["Time"]>;
  createdAtNEQ?: InputMaybe<Scalars["Time"]>;
  createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  /** events edge predicates */
  hasEvents?: InputMaybe<Scalars["Boolean"]>;
  hasEventsWith?: InputMaybe<Array<DeploymentLogWhereInput>>;
  /** service edge predicates */
  hasService?: InputMaybe<Scalars["Boolean"]>;
  hasServiceWith?: InputMaybe<Array<ServiceWhereInput>>;
  /** teams edge predicates */
  hasTeams?: InputMaybe<Scalars["Boolean"]>;
  hasTeamsWith?: InputMaybe<Array<TeamWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<DeploymentWhereInput>;
  or?: InputMaybe<Array<DeploymentWhereInput>>;
  /** project field predicates */
  project?: InputMaybe<Scalars["String"]>;
  projectContains?: InputMaybe<Scalars["String"]>;
  projectContainsFold?: InputMaybe<Scalars["String"]>;
  projectEqualFold?: InputMaybe<Scalars["String"]>;
  projectGT?: InputMaybe<Scalars["String"]>;
  projectGTE?: InputMaybe<Scalars["String"]>;
  projectHasPrefix?: InputMaybe<Scalars["String"]>;
  projectHasSuffix?: InputMaybe<Scalars["String"]>;
  projectIn?: InputMaybe<Array<Scalars["String"]>>;
  projectLT?: InputMaybe<Scalars["String"]>;
  projectLTE?: InputMaybe<Scalars["String"]>;
  projectNEQ?: InputMaybe<Scalars["String"]>;
  projectNotIn?: InputMaybe<Array<Scalars["String"]>>;
  /** service_id field predicates */
  serviceID?: InputMaybe<Scalars["ID"]>;
  serviceIDContains?: InputMaybe<Scalars["ID"]>;
  serviceIDContainsFold?: InputMaybe<Scalars["ID"]>;
  serviceIDEqualFold?: InputMaybe<Scalars["ID"]>;
  serviceIDGT?: InputMaybe<Scalars["ID"]>;
  serviceIDGTE?: InputMaybe<Scalars["ID"]>;
  serviceIDHasPrefix?: InputMaybe<Scalars["ID"]>;
  serviceIDHasSuffix?: InputMaybe<Scalars["ID"]>;
  serviceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
  serviceIDLT?: InputMaybe<Scalars["ID"]>;
  serviceIDLTE?: InputMaybe<Scalars["ID"]>;
  serviceIDNEQ?: InputMaybe<Scalars["ID"]>;
  serviceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** spin_down_at field predicates */
  spinDownAt?: InputMaybe<Scalars["Time"]>;
  spinDownAtGT?: InputMaybe<Scalars["Time"]>;
  spinDownAtGTE?: InputMaybe<Scalars["Time"]>;
  spinDownAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  spinDownAtIsNil?: InputMaybe<Scalars["Boolean"]>;
  spinDownAtLT?: InputMaybe<Scalars["Time"]>;
  spinDownAtLTE?: InputMaybe<Scalars["Time"]>;
  spinDownAtNEQ?: InputMaybe<Scalars["Time"]>;
  spinDownAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  spinDownAtNotNil?: InputMaybe<Scalars["Boolean"]>;
  /** spin_up_at field predicates */
  spinUpAt?: InputMaybe<Scalars["Time"]>;
  spinUpAtGT?: InputMaybe<Scalars["Time"]>;
  spinUpAtGTE?: InputMaybe<Scalars["Time"]>;
  spinUpAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  spinUpAtIsNil?: InputMaybe<Scalars["Boolean"]>;
  spinUpAtLT?: InputMaybe<Scalars["Time"]>;
  spinUpAtLTE?: InputMaybe<Scalars["Time"]>;
  spinUpAtNEQ?: InputMaybe<Scalars["Time"]>;
  spinUpAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  spinUpAtNotNil?: InputMaybe<Scalars["Boolean"]>;
  /** status field predicates */
  status?: InputMaybe<DeploymentStatus>;
  statusIn?: InputMaybe<Array<DeploymentStatus>>;
  statusNEQ?: InputMaybe<DeploymentStatus>;
  statusNotIn?: InputMaybe<Array<DeploymentStatus>>;
  /** tier field predicates */
  tier?: InputMaybe<DeploymentTier>;
  tierIn?: InputMaybe<Array<DeploymentTier>>;
  tierNEQ?: InputMaybe<DeploymentTier>;
  tierNotIn?: InputMaybe<Array<DeploymentTier>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars["Time"]>;
  updatedAtGT?: InputMaybe<Scalars["Time"]>;
  updatedAtGTE?: InputMaybe<Scalars["Time"]>;
  updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  updatedAtLT?: InputMaybe<Scalars["Time"]>;
  updatedAtLTE?: InputMaybe<Scalars["Time"]>;
  updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
  updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type File = Node & {
  __typename?: "File";
  alt?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Time"];
  directory: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  priority: Scalars["Int"];
  thumbnail: Scalars["String"];
  updatedAt: Scalars["Time"];
  uri: Scalars["String"];
};

/** A connection to a list of items. */
export type FileConnection = {
  __typename?: "FileConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FileEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type FileEdge = {
  __typename?: "FileEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<File>;
};

/** Ordering options for File connections */
export type FileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Files. */
  field: FileOrderField;
};

/** Properties by which File connections can be ordered. */
export enum FileOrderField {
  CreatedAt = "CREATED_AT",
  Priority = "PRIORITY",
}

/**
 * FileWhereInput is used for filtering File objects.
 * Input was generated by ent.
 */
export type FileWhereInput = {
  /** alt field predicates */
  alt?: InputMaybe<Scalars["String"]>;
  altContains?: InputMaybe<Scalars["String"]>;
  altContainsFold?: InputMaybe<Scalars["String"]>;
  altEqualFold?: InputMaybe<Scalars["String"]>;
  altGT?: InputMaybe<Scalars["String"]>;
  altGTE?: InputMaybe<Scalars["String"]>;
  altHasPrefix?: InputMaybe<Scalars["String"]>;
  altHasSuffix?: InputMaybe<Scalars["String"]>;
  altIn?: InputMaybe<Array<Scalars["String"]>>;
  altIsNil?: InputMaybe<Scalars["Boolean"]>;
  altLT?: InputMaybe<Scalars["String"]>;
  altLTE?: InputMaybe<Scalars["String"]>;
  altNEQ?: InputMaybe<Scalars["String"]>;
  altNotIn?: InputMaybe<Array<Scalars["String"]>>;
  altNotNil?: InputMaybe<Scalars["Boolean"]>;
  and?: InputMaybe<Array<FileWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars["Time"]>;
  createdAtGT?: InputMaybe<Scalars["Time"]>;
  createdAtGTE?: InputMaybe<Scalars["Time"]>;
  createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  createdAtLT?: InputMaybe<Scalars["Time"]>;
  createdAtLTE?: InputMaybe<Scalars["Time"]>;
  createdAtNEQ?: InputMaybe<Scalars["Time"]>;
  createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  /** directory field predicates */
  directory?: InputMaybe<Scalars["String"]>;
  directoryContains?: InputMaybe<Scalars["String"]>;
  directoryContainsFold?: InputMaybe<Scalars["String"]>;
  directoryEqualFold?: InputMaybe<Scalars["String"]>;
  directoryGT?: InputMaybe<Scalars["String"]>;
  directoryGTE?: InputMaybe<Scalars["String"]>;
  directoryHasPrefix?: InputMaybe<Scalars["String"]>;
  directoryHasSuffix?: InputMaybe<Scalars["String"]>;
  directoryIn?: InputMaybe<Array<Scalars["String"]>>;
  directoryLT?: InputMaybe<Scalars["String"]>;
  directoryLTE?: InputMaybe<Scalars["String"]>;
  directoryNEQ?: InputMaybe<Scalars["String"]>;
  directoryNotIn?: InputMaybe<Array<Scalars["String"]>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** name field predicates */
  name?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  nameContainsFold?: InputMaybe<Scalars["String"]>;
  nameEqualFold?: InputMaybe<Scalars["String"]>;
  nameGT?: InputMaybe<Scalars["String"]>;
  nameGTE?: InputMaybe<Scalars["String"]>;
  nameHasPrefix?: InputMaybe<Scalars["String"]>;
  nameHasSuffix?: InputMaybe<Scalars["String"]>;
  nameIn?: InputMaybe<Array<Scalars["String"]>>;
  nameLT?: InputMaybe<Scalars["String"]>;
  nameLTE?: InputMaybe<Scalars["String"]>;
  nameNEQ?: InputMaybe<Scalars["String"]>;
  nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
  not?: InputMaybe<FileWhereInput>;
  or?: InputMaybe<Array<FileWhereInput>>;
  /** priority field predicates */
  priority?: InputMaybe<Scalars["Int"]>;
  priorityGT?: InputMaybe<Scalars["Int"]>;
  priorityGTE?: InputMaybe<Scalars["Int"]>;
  priorityIn?: InputMaybe<Array<Scalars["Int"]>>;
  priorityLT?: InputMaybe<Scalars["Int"]>;
  priorityLTE?: InputMaybe<Scalars["Int"]>;
  priorityNEQ?: InputMaybe<Scalars["Int"]>;
  priorityNotIn?: InputMaybe<Array<Scalars["Int"]>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars["Time"]>;
  updatedAtGT?: InputMaybe<Scalars["Time"]>;
  updatedAtGTE?: InputMaybe<Scalars["Time"]>;
  updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  updatedAtLT?: InputMaybe<Scalars["Time"]>;
  updatedAtLTE?: InputMaybe<Scalars["Time"]>;
  updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
  updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type HasValueInput = {
  index: Scalars["Int"];
  value: Scalars["String"];
};

export type KatanaAccount = {
  __typename?: "KatanaAccount";
  address: Scalars["String"];
  privateKey: Scalars["String"];
  publicKey: Scalars["String"];
};

export type KatanaConfig = {
  __typename?: "KatanaConfig";
  accounts?: Maybe<Array<KatanaAccount>>;
  blockTime?: Maybe<Scalars["Int"]>;
  chainId: Scalars["String"];
  disableFee?: Maybe<Scalars["Boolean"]>;
  forkBlockNumber?: Maybe<Scalars["Long"]>;
  forkRpcUrl?: Maybe<Scalars["String"]>;
  gasPrice?: Maybe<Scalars["Long"]>;
  genesis?: Maybe<Scalars["String"]>;
  invokeMaxSteps?: Maybe<Scalars["Long"]>;
  rpc: Scalars["String"];
  seed: Scalars["String"];
  validateMaxSteps?: Maybe<Scalars["Long"]>;
  version: Scalars["String"];
};

export type L1Message = {
  __typename?: "L1Message";
  payload?: Maybe<Array<Scalars["Felt"]>>;
  toAddress: Scalars["String"];
};

export type L2Message = {
  __typename?: "L2Message";
  fromAddress: Scalars["String"];
  payload?: Maybe<Array<Scalars["Felt"]>>;
};

export type Logs = {
  __typename?: "Logs";
  content: Scalars["String"];
  until: Scalars["Time"];
};

export type Mutation = {
  __typename?: "Mutation";
  addToTeam: Scalars["Boolean"];
  beginLogin: Scalars["JSON"];
  beginRegistration: Scalars["JSON"];
  createDeployment: DeploymentConfig;
  deleteDeployment: Scalars["Boolean"];
  finalizeLogin: Scalars["String"];
  finalizeRegistration: Account;
  forkDeployment: DeploymentConfig;
  removeFromTeam: Scalars["Boolean"];
  updateDeployment: DeploymentConfig;
  updateFile: Scalars["Boolean"];
  upload: Array<File>;
};

export type MutationAddToTeamArgs = {
  teamID: Scalars["ID"];
  userIDs: Array<Scalars["ID"]>;
};

export type MutationBeginLoginArgs = {
  id: Scalars["String"];
};

export type MutationBeginRegistrationArgs = {
  id: Scalars["String"];
};

export type MutationCreateDeploymentArgs = {
  name: Scalars["String"];
  regions?: InputMaybe<Array<Scalars["String"]>>;
  service: CreateServiceInput;
  tier?: InputMaybe<DeploymentTier>;
  wait?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationDeleteDeploymentArgs = {
  name: Scalars["String"];
  service: DeploymentService;
};

export type MutationFinalizeLoginArgs = {
  credentials: Scalars["String"];
};

export type MutationFinalizeRegistrationArgs = {
  credentials: Scalars["String"];
  network: Scalars["String"];
};

export type MutationForkDeploymentArgs = {
  forkBlockNumber: Scalars["Long"];
  forkName: Scalars["String"];
  name: Scalars["String"];
  tier?: InputMaybe<DeploymentTier>;
  wait?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationRemoveFromTeamArgs = {
  teamID: Scalars["ID"];
  userIDs: Array<Scalars["ID"]>;
};

export type MutationUpdateDeploymentArgs = {
  name: Scalars["String"];
  service: UpdateServiceInput;
  tier?: InputMaybe<DeploymentTier>;
  wait?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateFileArgs = {
  id: Scalars["ID"];
  priority: Scalars["Int"];
};

export type MutationUploadArgs = {
  req: Array<UploadFile>;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
};

export enum Order {
  Asc = "asc",
  Desc = "desc",
}

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = "ASC",
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = "DESC",
}

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["Cursor"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["Cursor"]>;
};

export type Price = {
  __typename?: "Price";
  amount?: Maybe<Scalars["String"]>;
  base?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  account?: Maybe<Account>;
  accounts?: Maybe<AccountConnection>;
  deployment?: Maybe<Deployment>;
  deployments?: Maybe<DeploymentConnection>;
  me?: Maybe<Account>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  price?: Maybe<Price>;
  team?: Maybe<Team>;
  teams?: Maybe<TeamConnection>;
};

export type QueryAccountArgs = {
  id: Scalars["ID"];
};

export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AccountOrder>;
  where?: InputMaybe<AccountWhereInput>;
};

export type QueryDeploymentArgs = {
  name: Scalars["String"];
  service: DeploymentService;
};

export type QueryDeploymentsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<DeploymentOrder>;
  where?: InputMaybe<DeploymentWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryNodesArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryPriceArgs = {
  base: CurrencyBase;
  quote: CurrencyQuote;
};

export type QueryTeamArgs = {
  id: Scalars["ID"];
};

export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TeamWhereInput>;
};

export type Resources = {
  __typename?: "Resources";
  cpu?: Maybe<Scalars["Float"]>;
  memory?: Maybe<Scalars["Float"]>;
};

export enum Role {
  Admin = "ADMIN",
  User = "USER",
}

export type SayaConfig = {
  __typename?: "SayaConfig";
  batchSize: Scalars["Int"];
  mode: Scalars["String"];
  privateKey: Scalars["String"];
  proverUrl: Scalars["String"];
  registry: Scalars["String"];
  rpcUrl: Scalars["String"];
  settlementContract: Scalars["String"];
  signerAddress: Scalars["String"];
  signerKey: Scalars["String"];
  starknetUrl: Scalars["String"];
  startBlock: Scalars["Int"];
  storeProofs: Scalars["Boolean"];
  version: Scalars["String"];
  world: Scalars["String"];
};

export type Service = Node & {
  __typename?: "Service";
  createdAt: Scalars["Time"];
  defaultVersion: Scalars["String"];
  deployments?: Maybe<Array<Deployment>>;
  id: Scalars["ID"];
  updatedAt: Scalars["Time"];
  versions: Array<Scalars["String"]>;
};

/** A connection to a list of items. */
export type ServiceConnection = {
  __typename?: "ServiceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ServiceEdge = {
  __typename?: "ServiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<Service>;
};

/** Ordering options for Service connections */
export type ServiceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Services. */
  field: ServiceOrderField;
};

/** Properties by which Service connections can be ordered. */
export enum ServiceOrderField {
  CreatedAt = "CREATED_AT",
}

/**
 * ServiceWhereInput is used for filtering Service objects.
 * Input was generated by ent.
 */
export type ServiceWhereInput = {
  and?: InputMaybe<Array<ServiceWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars["Time"]>;
  createdAtGT?: InputMaybe<Scalars["Time"]>;
  createdAtGTE?: InputMaybe<Scalars["Time"]>;
  createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  createdAtLT?: InputMaybe<Scalars["Time"]>;
  createdAtLTE?: InputMaybe<Scalars["Time"]>;
  createdAtNEQ?: InputMaybe<Scalars["Time"]>;
  createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  /** default_version field predicates */
  defaultVersion?: InputMaybe<Scalars["String"]>;
  defaultVersionContains?: InputMaybe<Scalars["String"]>;
  defaultVersionContainsFold?: InputMaybe<Scalars["String"]>;
  defaultVersionEqualFold?: InputMaybe<Scalars["String"]>;
  defaultVersionGT?: InputMaybe<Scalars["String"]>;
  defaultVersionGTE?: InputMaybe<Scalars["String"]>;
  defaultVersionHasPrefix?: InputMaybe<Scalars["String"]>;
  defaultVersionHasSuffix?: InputMaybe<Scalars["String"]>;
  defaultVersionIn?: InputMaybe<Array<Scalars["String"]>>;
  defaultVersionLT?: InputMaybe<Scalars["String"]>;
  defaultVersionLTE?: InputMaybe<Scalars["String"]>;
  defaultVersionNEQ?: InputMaybe<Scalars["String"]>;
  defaultVersionNotIn?: InputMaybe<Array<Scalars["String"]>>;
  /** deployments edge predicates */
  hasDeployments?: InputMaybe<Scalars["Boolean"]>;
  hasDeploymentsWith?: InputMaybe<Array<DeploymentWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<ServiceWhereInput>;
  or?: InputMaybe<Array<ServiceWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars["Time"]>;
  updatedAtGT?: InputMaybe<Scalars["Time"]>;
  updatedAtGTE?: InputMaybe<Scalars["Time"]>;
  updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  updatedAtLT?: InputMaybe<Scalars["Time"]>;
  updatedAtLTE?: InputMaybe<Scalars["Time"]>;
  updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
  updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type Signer = Node & {
  __typename?: "Signer";
  controller: Controller;
  controllerID: Scalars["ID"];
  createdAt: Scalars["Time"];
  id: Scalars["ID"];
  type: SignerType;
  updatedAt: Scalars["Time"];
};

/** Ordering options for Signer connections */
export type SignerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Signers. */
  field: SignerOrderField;
};

/** Properties by which Signer connections can be ordered. */
export enum SignerOrderField {
  CreatedAt = "CREATED_AT",
}

/** SignerType is enum for the field type */
export enum SignerType {
  StarknetAccount = "starknet_account",
  Webauthn = "webauthn",
}

/**
 * SignerWhereInput is used for filtering Signer objects.
 * Input was generated by ent.
 */
export type SignerWhereInput = {
  and?: InputMaybe<Array<SignerWhereInput>>;
  /** controller_id field predicates */
  controllerID?: InputMaybe<Scalars["ID"]>;
  controllerIDContains?: InputMaybe<Scalars["ID"]>;
  controllerIDContainsFold?: InputMaybe<Scalars["ID"]>;
  controllerIDEqualFold?: InputMaybe<Scalars["ID"]>;
  controllerIDGT?: InputMaybe<Scalars["ID"]>;
  controllerIDGTE?: InputMaybe<Scalars["ID"]>;
  controllerIDHasPrefix?: InputMaybe<Scalars["ID"]>;
  controllerIDHasSuffix?: InputMaybe<Scalars["ID"]>;
  controllerIDIn?: InputMaybe<Array<Scalars["ID"]>>;
  controllerIDLT?: InputMaybe<Scalars["ID"]>;
  controllerIDLTE?: InputMaybe<Scalars["ID"]>;
  controllerIDNEQ?: InputMaybe<Scalars["ID"]>;
  controllerIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars["Time"]>;
  createdAtGT?: InputMaybe<Scalars["Time"]>;
  createdAtGTE?: InputMaybe<Scalars["Time"]>;
  createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  createdAtLT?: InputMaybe<Scalars["Time"]>;
  createdAtLTE?: InputMaybe<Scalars["Time"]>;
  createdAtNEQ?: InputMaybe<Scalars["Time"]>;
  createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
  /** controller edge predicates */
  hasController?: InputMaybe<Scalars["Boolean"]>;
  hasControllerWith?: InputMaybe<Array<ControllerWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<SignerWhereInput>;
  or?: InputMaybe<Array<SignerWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<SignerType>;
  typeIn?: InputMaybe<Array<SignerType>>;
  typeNEQ?: InputMaybe<SignerType>;
  typeNotIn?: InputMaybe<Array<SignerType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars["Time"]>;
  updatedAtGT?: InputMaybe<Scalars["Time"]>;
  updatedAtGTE?: InputMaybe<Scalars["Time"]>;
  updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
  updatedAtLT?: InputMaybe<Scalars["Time"]>;
  updatedAtLTE?: InputMaybe<Scalars["Time"]>;
  updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
  updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type Team = Node & {
  __typename?: "Team";
  deployments: DeploymentConnection;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  members: AccountConnection;
  membership: AccountTeamConnection;
  name: Scalars["String"];
};

export type TeamDeploymentsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<DeploymentOrder>;
  where?: InputMaybe<DeploymentWhereInput>;
};

export type TeamMembersArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AccountOrder>;
  where?: InputMaybe<AccountWhereInput>;
};

export type TeamMembershipArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AccountTeamWhereInput>;
};

/** A connection to a list of items. */
export type TeamConnection = {
  __typename?: "TeamConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TeamEdge = {
  __typename?: "TeamEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<Team>;
};

/**
 * TeamWhereInput is used for filtering Team objects.
 * Input was generated by ent.
 */
export type TeamWhereInput = {
  and?: InputMaybe<Array<TeamWhereInput>>;
  /** description field predicates */
  description?: InputMaybe<Scalars["String"]>;
  descriptionContains?: InputMaybe<Scalars["String"]>;
  descriptionContainsFold?: InputMaybe<Scalars["String"]>;
  descriptionEqualFold?: InputMaybe<Scalars["String"]>;
  descriptionGT?: InputMaybe<Scalars["String"]>;
  descriptionGTE?: InputMaybe<Scalars["String"]>;
  descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
  descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
  descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
  descriptionIsNil?: InputMaybe<Scalars["Boolean"]>;
  descriptionLT?: InputMaybe<Scalars["String"]>;
  descriptionLTE?: InputMaybe<Scalars["String"]>;
  descriptionNEQ?: InputMaybe<Scalars["String"]>;
  descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
  descriptionNotNil?: InputMaybe<Scalars["Boolean"]>;
  /** deployments edge predicates */
  hasDeployments?: InputMaybe<Scalars["Boolean"]>;
  hasDeploymentsWith?: InputMaybe<Array<DeploymentWhereInput>>;
  /** members edge predicates */
  hasMembers?: InputMaybe<Scalars["Boolean"]>;
  hasMembersWith?: InputMaybe<Array<AccountWhereInput>>;
  /** membership edge predicates */
  hasMembership?: InputMaybe<Scalars["Boolean"]>;
  hasMembershipWith?: InputMaybe<Array<AccountTeamWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** name field predicates */
  name?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  nameContainsFold?: InputMaybe<Scalars["String"]>;
  nameEqualFold?: InputMaybe<Scalars["String"]>;
  nameGT?: InputMaybe<Scalars["String"]>;
  nameGTE?: InputMaybe<Scalars["String"]>;
  nameHasPrefix?: InputMaybe<Scalars["String"]>;
  nameHasSuffix?: InputMaybe<Scalars["String"]>;
  nameIn?: InputMaybe<Array<Scalars["String"]>>;
  nameLT?: InputMaybe<Scalars["String"]>;
  nameLTE?: InputMaybe<Scalars["String"]>;
  nameNEQ?: InputMaybe<Scalars["String"]>;
  nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
  not?: InputMaybe<TeamWhereInput>;
  or?: InputMaybe<Array<TeamWhereInput>>;
};

export type ToriiConfig = {
  __typename?: "ToriiConfig";
  graphql: Scalars["String"];
  grpc: Scalars["String"];
  indexPending?: Maybe<Scalars["Boolean"]>;
  pollingInterval?: Maybe<Scalars["Long"]>;
  rpc: Scalars["String"];
  startBlock?: Maybe<Scalars["Long"]>;
  version: Scalars["String"];
  world: Scalars["String"];
};

export type TransactionReceipt = {
  __typename?: "TransactionReceipt";
  messagesSent: Array<Maybe<L1Message>>;
};

export type UpdateKatanaConfigInput = {
  blockTime?: InputMaybe<Scalars["Int"]>;
  dev?: InputMaybe<Scalars["Boolean"]>;
  disableFee?: InputMaybe<Scalars["Boolean"]>;
  gasPrice?: InputMaybe<Scalars["Long"]>;
  invokeMaxSteps?: InputMaybe<Scalars["Long"]>;
  validateMaxSteps?: InputMaybe<Scalars["Long"]>;
};

export type UpdateServiceConfigInput = {
  katana?: InputMaybe<UpdateKatanaConfigInput>;
};

export type UpdateServiceInput = {
  config?: InputMaybe<UpdateServiceConfigInput>;
  type: DeploymentService;
  version?: InputMaybe<Scalars["String"]>;
};

/** The `UploadFile` type, represents the request for uploading a file with a certain payload. */
export type UploadFile = {
  alt?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  id: Scalars["Int"];
};

export type WebauthnCredential = {
  __typename?: "WebauthnCredential";
  id: Scalars["ID"];
  publicKey: Scalars["String"];
};

export type AccountInfoQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type AccountInfoQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "AccountConnection";
    edges?: Array<{
      __typename?: "AccountEdge";
      node?: { __typename?: "Account"; id: string } | null;
    } | null> | null;
  } | null;
};

export type BeginRegistrationMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type BeginRegistrationMutation = {
  __typename?: "Mutation";
  beginRegistration: any;
};

export type FinalizeRegistrationMutationVariables = Exact<{
  credentials: Scalars["String"];
  network: Scalars["String"];
}>;

export type FinalizeRegistrationMutation = {
  __typename?: "Mutation";
  finalizeRegistration: {
    __typename?: "Account";
    id: string;
    controllers?: Array<{
      __typename?: "Controller";
      address: string;
      signers?: Array<{ __typename?: "Signer"; type: SignerType }> | null;
    }> | null;
    credentials: {
      __typename?: "Credentials";
      webauthn?: Array<{
        __typename?: "WebauthnCredential";
        id: string;
        publicKey: string;
      }> | null;
    };
  };
};

export type AccountQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AccountQuery = {
  __typename?: "Query";
  account?: {
    __typename?: "Account";
    id: string;
    credentials: {
      __typename?: "Credentials";
      webauthn?: Array<{
        __typename?: "WebauthnCredential";
        id: string;
        publicKey: string;
      }> | null;
    };
    controllers?: Array<{
      __typename?: "Controller";
      address: string;
      signers?: Array<{ __typename?: "Signer"; type: SignerType }> | null;
    }> | null;
  } | null;
};

export type BeginLoginMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type BeginLoginMutation = { __typename?: "Mutation"; beginLogin: any };

export type FinalizeLoginMutationVariables = Exact<{
  credentials: Scalars["String"];
}>;

export type FinalizeLoginMutation = {
  __typename?: "Mutation";
  finalizeLogin: string;
};

export type PriceQueryVariables = Exact<{
  quote: CurrencyQuote;
  base: CurrencyBase;
}>;

export type PriceQuery = {
  __typename?: "Query";
  price?: {
    __typename?: "Price";
    amount?: string | null;
    currency?: string | null;
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: { __typename?: "Account"; id: string } | null;
};

export type FetchControllersQueryVariables = Exact<{
  addresses: Array<Scalars["String"]> | Scalars["String"];
  first?: InputMaybe<Scalars["Int"]>;
}>;

export type FetchControllersQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "AccountConnection";
    edges?: Array<{
      __typename?: "AccountEdge";
      node?: {
        __typename?: "Account";
        id: string;
        controllers?: Array<{
          __typename?: "Controller";
          address: string;
        }> | null;
      } | null;
    } | null> | null;
  } | null;
};

export const AccountInfoDocument = `
    query AccountInfo($address: String!) {
  accounts(where: {hasControllersWith: {address: $address}}, first: 1) {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const useAccountInfoQuery = <TData = AccountInfoQuery, TError = unknown>(
  variables: AccountInfoQueryVariables,
  options?: UseQueryOptions<AccountInfoQuery, TError, TData>,
) =>
  useQuery<AccountInfoQuery, TError, TData>(
    ["AccountInfo", variables],
    useFetchData<AccountInfoQuery, AccountInfoQueryVariables>(
      AccountInfoDocument,
    ).bind(null, variables),
    options,
  );

useAccountInfoQuery.getKey = (variables: AccountInfoQueryVariables) => [
  "AccountInfo",
  variables,
];
export const useInfiniteAccountInfoQuery = <
  TData = AccountInfoQuery,
  TError = unknown,
>(
  variables: AccountInfoQueryVariables,
  options?: UseInfiniteQueryOptions<AccountInfoQuery, TError, TData>,
) => {
  const query = useFetchData<AccountInfoQuery, AccountInfoQueryVariables>(
    AccountInfoDocument,
  );
  return useInfiniteQuery<AccountInfoQuery, TError, TData>(
    ["AccountInfo.infinite", variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options,
  );
};

useInfiniteAccountInfoQuery.getKey = (variables: AccountInfoQueryVariables) => [
  "AccountInfo.infinite",
  variables,
];
export const BeginRegistrationDocument = `
    mutation BeginRegistration($id: String!) {
  beginRegistration(id: $id)
}
    `;
export const useBeginRegistrationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    BeginRegistrationMutation,
    TError,
    BeginRegistrationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    BeginRegistrationMutation,
    TError,
    BeginRegistrationMutationVariables,
    TContext
  >(
    ["BeginRegistration"],
    useFetchData<BeginRegistrationMutation, BeginRegistrationMutationVariables>(
      BeginRegistrationDocument,
    ),
    options,
  );
export const FinalizeRegistrationDocument = `
    mutation FinalizeRegistration($credentials: String!, $network: String!) {
  finalizeRegistration(credentials: $credentials, network: $network) {
    id
    controllers {
      address
      signers {
        type
      }
    }
    credentials {
      webauthn {
        id
        publicKey
      }
    }
  }
}
    `;
export const useFinalizeRegistrationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    FinalizeRegistrationMutation,
    TError,
    FinalizeRegistrationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    FinalizeRegistrationMutation,
    TError,
    FinalizeRegistrationMutationVariables,
    TContext
  >(
    ["FinalizeRegistration"],
    useFetchData<
      FinalizeRegistrationMutation,
      FinalizeRegistrationMutationVariables
    >(FinalizeRegistrationDocument),
    options,
  );
export const AccountDocument = `
    query Account($id: ID!) {
  account(id: $id) {
    id
    credentials {
      webauthn {
        id
        publicKey
      }
    }
    controllers {
      address
      signers {
        type
      }
    }
  }
}
    `;
export const useAccountQuery = <TData = AccountQuery, TError = unknown>(
  variables: AccountQueryVariables,
  options?: UseQueryOptions<AccountQuery, TError, TData>,
) =>
  useQuery<AccountQuery, TError, TData>(
    ["Account", variables],
    useFetchData<AccountQuery, AccountQueryVariables>(AccountDocument).bind(
      null,
      variables,
    ),
    options,
  );

useAccountQuery.getKey = (variables: AccountQueryVariables) => [
  "Account",
  variables,
];
export const useInfiniteAccountQuery = <TData = AccountQuery, TError = unknown>(
  variables: AccountQueryVariables,
  options?: UseInfiniteQueryOptions<AccountQuery, TError, TData>,
) => {
  const query = useFetchData<AccountQuery, AccountQueryVariables>(
    AccountDocument,
  );
  return useInfiniteQuery<AccountQuery, TError, TData>(
    ["Account.infinite", variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options,
  );
};

useInfiniteAccountQuery.getKey = (variables: AccountQueryVariables) => [
  "Account.infinite",
  variables,
];
export const BeginLoginDocument = `
    mutation BeginLogin($id: String!) {
  beginLogin(id: $id)
}
    `;
export const useBeginLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    BeginLoginMutation,
    TError,
    BeginLoginMutationVariables,
    TContext
  >,
) =>
  useMutation<
    BeginLoginMutation,
    TError,
    BeginLoginMutationVariables,
    TContext
  >(
    ["BeginLogin"],
    useFetchData<BeginLoginMutation, BeginLoginMutationVariables>(
      BeginLoginDocument,
    ),
    options,
  );
export const FinalizeLoginDocument = `
    mutation FinalizeLogin($credentials: String!) {
  finalizeLogin(credentials: $credentials)
}
    `;
export const useFinalizeLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    FinalizeLoginMutation,
    TError,
    FinalizeLoginMutationVariables,
    TContext
  >,
) =>
  useMutation<
    FinalizeLoginMutation,
    TError,
    FinalizeLoginMutationVariables,
    TContext
  >(
    ["FinalizeLogin"],
    useFetchData<FinalizeLoginMutation, FinalizeLoginMutationVariables>(
      FinalizeLoginDocument,
    ),
    options,
  );
export const PriceDocument = `
    query Price($quote: CurrencyQuote!, $base: CurrencyBase!) {
  price(quote: $quote, base: $base) {
    amount
    currency
  }
}
    `;
export const usePriceQuery = <TData = PriceQuery, TError = unknown>(
  variables: PriceQueryVariables,
  options?: UseQueryOptions<PriceQuery, TError, TData>,
) =>
  useQuery<PriceQuery, TError, TData>(
    ["Price", variables],
    useFetchData<PriceQuery, PriceQueryVariables>(PriceDocument).bind(
      null,
      variables,
    ),
    options,
  );

usePriceQuery.getKey = (variables: PriceQueryVariables) => ["Price", variables];
export const useInfinitePriceQuery = <TData = PriceQuery, TError = unknown>(
  variables: PriceQueryVariables,
  options?: UseInfiniteQueryOptions<PriceQuery, TError, TData>,
) => {
  const query = useFetchData<PriceQuery, PriceQueryVariables>(PriceDocument);
  return useInfiniteQuery<PriceQuery, TError, TData>(
    ["Price.infinite", variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options,
  );
};

useInfinitePriceQuery.getKey = (variables: PriceQueryVariables) => [
  "Price.infinite",
  variables,
];
export const MeDocument = `
    query Me {
  me {
    id
  }
}
    `;
export const useMeQuery = <TData = MeQuery, TError = unknown>(
  variables?: MeQueryVariables,
  options?: UseQueryOptions<MeQuery, TError, TData>,
) =>
  useQuery<MeQuery, TError, TData>(
    variables === undefined ? ["Me"] : ["Me", variables],
    useFetchData<MeQuery, MeQueryVariables>(MeDocument).bind(null, variables),
    options,
  );

useMeQuery.getKey = (variables?: MeQueryVariables) =>
  variables === undefined ? ["Me"] : ["Me", variables];
export const useInfiniteMeQuery = <TData = MeQuery, TError = unknown>(
  variables?: MeQueryVariables,
  options?: UseInfiniteQueryOptions<MeQuery, TError, TData>,
) => {
  const query = useFetchData<MeQuery, MeQueryVariables>(MeDocument);
  return useInfiniteQuery<MeQuery, TError, TData>(
    variables === undefined ? ["Me.infinite"] : ["Me.infinite", variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options,
  );
};

useInfiniteMeQuery.getKey = (variables?: MeQueryVariables) =>
  variables === undefined ? ["Me.infinite"] : ["Me.infinite", variables];
export const FetchControllersDocument = `
    query FetchControllers($addresses: [String!]!, $first: Int) {
  accounts(where: {hasControllersWith: [{addressIn: $addresses}]}, first: $first) {
    edges {
      node {
        id
        controllers {
          address
        }
      }
    }
  }
}
    `;
export const useFetchControllersQuery = <
  TData = FetchControllersQuery,
  TError = unknown,
>(
  variables: FetchControllersQueryVariables,
  options?: UseQueryOptions<FetchControllersQuery, TError, TData>,
) =>
  useQuery<FetchControllersQuery, TError, TData>(
    ["FetchControllers", variables],
    useFetchData<FetchControllersQuery, FetchControllersQueryVariables>(
      FetchControllersDocument,
    ).bind(null, variables),
    options,
  );

useFetchControllersQuery.getKey = (
  variables: FetchControllersQueryVariables,
) => ["FetchControllers", variables];
export const useInfiniteFetchControllersQuery = <
  TData = FetchControllersQuery,
  TError = unknown,
>(
  variables: FetchControllersQueryVariables,
  options?: UseInfiniteQueryOptions<FetchControllersQuery, TError, TData>,
) => {
  const query = useFetchData<
    FetchControllersQuery,
    FetchControllersQueryVariables
  >(FetchControllersDocument);
  return useInfiniteQuery<FetchControllersQuery, TError, TData>(
    ["FetchControllers.infinite", variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options,
  );
};

useInfiniteFetchControllersQuery.getKey = (
  variables: FetchControllersQueryVariables,
) => ["FetchControllers.infinite", variables];
